import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const ThanksPage = () => {
  return (
    <Layout>
      <div>
        <h1>Thank you! We'll be in touch shortly</h1>
        <br />
        <br />
        <br />
        <h3>
          <Link to="/">Click here to go to the home page!</Link>
        </h3>
      </div>
    </Layout>
  )
}

export default ThanksPage
